import React from "react";
// Import Section Heading
import SectionHeading from "../../Home/SectionHeading/SectionHeading";

// Import TeamData
import { TeamData } from "../Team/TeamData";

// Import TeamCard
import TeamCard from "../Team/TeamCard";

const TeamMembers = ({ className = "" }) => {
  return (
    <>
      {/* Team Area Start */}
      <section id="team-area" className={`py100 ${className}`}>
        <div className="container">
          <SectionHeading title="Our Team" />
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="about-img">
              <p>
                Our team at Calidad Technologies Pvt Ltd comprises highly
                skilled and passionate professionals who are the driving force
                behind our success. From expert web developers to proficient
                data scientists, our collective expertise enables us to tackle
                complex challenges and deliver exceptional solutions. Each
                member of our team is dedicated to staying ahead of the curve,
                embracing new technologies, and honing their skills to provide
                the best possible services to our clients.
              </p>
            </div>
          </div>
          <SectionHeading heading="Meet Our Expert Team" />
          <div className="row">
            {TeamData.map((data, index) => (
              <TeamCard data={data} key={index} />
            ))}
          </div>
        </div>
      </section>
      {/* Team Area End */}
    </>
  );
};

export default TeamMembers;
