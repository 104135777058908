import React from 'react'
import { Link } from "react-router-dom";
//import CommonBanner from '../component/Common/Banner'
import OurServices from '../component/Home/OurService/OurServices'
import GetStarted from '../component/Common/GetStarted/index'
//import Counter from '../component/Common/Counter/index'
//import Blog from '../component/Home/Blog/Blog'

const CommonBannerServices = (props) => {
  return (
    <>
      <section id="banner-inner-area" className="contactus-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-details text-center">
                <h2>{props.heading}</h2>
                <ul>
                  <li>
                    <Link to="/">{props.menu1}</Link>
                  </li>
                  <li>
                    <span>/</span>
                    {props.menu2}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Services = () => {
    return (
      <>
        <CommonBannerServices
          heading="Services"
          menu1="Home"
          menu2="Services"
        />
        <OurServices />
        <GetStarted />
        {/* <Counter /> */}
        {/* <Blog /> */}
      </>
    );
}

export default Services
