// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    /* --main-theme-color: #5b6fe6; */
    --main-theme-color: #124BC0;
    --dark-bg-color: #f8f9ff;
    --heading-color: #2c3552;
    --paragraph-color: #777c87;
    --banner-paragraph-color: #344a6f;
}

/*text color*/


.single-blog-post .post-content h3 a,
.single-box a:hover h4,
a.text-link:hover,
.widget-link-items li a:hover,
.title-right-icon li:nth-child(1) i,
.seat li:nth-child(1) i {
    color: var(--main-theme-color);
}

a,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--heading-color);
}`, "",{"version":3,"sources":["webpack://./src/assets/css/color.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,2BAA2B;IAC3B,wBAAwB;IACxB,wBAAwB;IACxB,0BAA0B;IAC1B,iCAAiC;AACrC;;AAEA,aAAa;;;AAGb;;;;;;IAMI,8BAA8B;AAClC;;AAEA;;;;;;;IAOI,2BAA2B;AAC/B","sourcesContent":[":root {\n    /* --main-theme-color: #5b6fe6; */\n    --main-theme-color: #124BC0;\n    --dark-bg-color: #f8f9ff;\n    --heading-color: #2c3552;\n    --paragraph-color: #777c87;\n    --banner-paragraph-color: #344a6f;\n}\n\n/*text color*/\n\n\n.single-blog-post .post-content h3 a,\n.single-box a:hover h4,\na.text-link:hover,\n.widget-link-items li a:hover,\n.title-right-icon li:nth-child(1) i,\n.seat li:nth-child(1) i {\n    color: var(--main-theme-color);\n}\n\na,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n    color: var(--heading-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
