import { Formik } from "formik";
import ContactForm from "./Contactform";
import * as Yup from "yup";
import axios from "axios";
import React, { useState } from "react";
import Formsuccess from "./Formsuccess";

function ContactFormspace( ) {
  const [errormsg, seterrormsg] = useState("");
  const [successmsg, setsuccessmsg] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const ErrorSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required")
      .min(3, "too Short")
      .max(12, "Too Long"),
    last_name: Yup.string()
      .required("Last Name is required")
      .min(3, "too Short")
      .max(12, "Too Long"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    message: Yup.string()
      .min(5, "Too Short!")
      .max(100, "Too Long!")
      .required("Message is Required"),
  });
  return (
    <div>
    {isSubmitted ? (
      <Formsuccess />
    ) : (
      <div className="contact-form-area box-shadow">
      <div>
          {errormsg ? <p className="error-message">{errormsg}</p> : null}
        </div>
        <div>
          {successmsg ? <p className="success-message">{successmsg}</p> : null}
        </div>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              message: "",
            }}
            onSubmit={(value, { resetForm }) => {
              const formData= value;
              axios.post("http://localhost/backend/Contact_controller/contactus",formData)
              .then((res) => {
                console.log(res);
                const msg = res.data.message;
                setsuccessmsg(msg);
                setIsSubmitted(true);
                setTimeout(() => {
                  setsuccessmsg("");
                }, 4000);
                resetForm();

              })
              .catch((err) => seterrormsg("Something Went Wrong"));
              setTimeout(() => {
                seterrormsg("");
              }, 4000);
            }}
            validationSchema={ErrorSchema}
            component={ContactForm}
          />
        </div>
     )}
    </div>
  );
}

export default ContactFormspace;
