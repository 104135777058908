import { Formik } from "formik";
import Requestquoteform from "./Requestquoteform";
import * as Yup from "yup";
import { React ,useState } from "react";
import axios from "axios";
import Formsuccess from "../component/Contact/Formsuccess";

function Requestquote() {

  const [errormsg, seterrormsg] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const ErrorSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("Enter First Name")
      .min(3, "too Short")
      .max(12, "Too Long"),
    last_name: Yup.string()
      .required("Enter Last Name")
      .min(3, "too Short")
      .max(12, "Too Long"),
    email: Yup.string().email("Invalid email").required("Enter Email"),
    message: Yup.string()
      .min(5, "Too Short!")
      .max(100, "Too Long!")
      .required("Enter Message"),
    mobile: Yup.string()
      .matches(/^\d{10}$/, "Enter 10 digit only")
      .required("Enter Number"),
    atLeastOneSelected: Yup.boolean()
      .test(
        'atLeastOneSelected',
        'Select at least one option',
        (value, { parent }) => {
          return (
            parent.Machine_learning ||
            parent.CMS_development ||
            parent.Web_Developement ||
            parent.Website_Design
          );
        }
      ),
    
  });

  return (
    <div className="contact-form-area box-shadow">
      {isSubmitted ? (
        <Formsuccess />
      ) : ( 
        <div className="requestquote">
          <div>
          {errormsg ? <p className="error-message">{errormsg}</p> : null}
        </div>
          <h2 className="heading">Request a Quote</h2>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              message: "",
              mobile: "",
              Machine_learning: false,
              CMS_development: false,
              Web_Developement: false,
              Website_Design: false,
              budget:[20000, 75000],
            }}
            // console.log()
            onSubmit={(value, { resetForm }) => {
              console.log(value);
              axios.post("http://localhost/backend/Contact_controller/requestquote",value)
              .then((res) => {
                if (res.data.message) {
                  const msg = res.data.message;
                  setIsSubmitted(true);
                  seterrormsg(msg)
                  // resetForm();

                }
                if (res.data.error) {
                  seterrormsg("Something Went Wrong")
                  setTimeout(() => {
                    seterrormsg("");
                  }, 4000);
                }
                console.log(res);


              })
              .catch((err) => seterrormsg("Something Went Wrong"));
              setTimeout(() => {
                seterrormsg("");
              }, 4000);
              
              
            }}
            validationSchema={ErrorSchema}
            component={Requestquoteform}
          />
        </div>
      )};
        
    </div>
  );
}

export default Requestquote;
