import React from 'react'
import { Link } from "react-router-dom";
import CommonBanner from '../component/Common/Banner'
import ContactDetails from '../component/Contact'

const CommonBannerContact = (props) => {
  return (
    <>
      <section id="banner-inner-area" className="contactus-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-details text-center">
                <h2>{props.heading}</h2>
                <ul>
                  <li>
                    <Link to="/">{props.menu1}</Link>
                  </li>
                  <li>
                    <span>/</span>
                    {props.menu2}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Contact = () => {
    return (
      <>
        <CommonBannerContact heading="Contact" menu1="Home" menu2="Contact" />
        <ContactDetails />
      </>
    );
}

export default Contact
