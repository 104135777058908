import React from "react";
import FormInput from "../From/index";
import { Form, Field } from "formik";

function ContactForm({ errors, touched}) {
  // console.log(errors);
  return (
    <Form>
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="form-group">
            <Field
              name="first_name"
              placeholder="Enter First Name"
              className={
                touched.first_name
                  ? `form-control ${errors.first_name ? "invalid" : "valid"}`
                  : `form-control`
              }
              type="text"
            />
              <p className="text-danger height">{errors.first_name}</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="form-group">
            <Field
              name="last_name"
              placeholder="Enter Last Name"
              className={
                touched.last_name
                  ? `form-control ${errors.last_name ? "invalid" : "valid"}`
                  : `form-control`
              }
              type="text"
            />
              <p className="text-danger height">{errors.last_name}</p>
          </div>
        </div>
        <div className="col-lg-12  col-md-12 col-sm-12 col-12">
          <div className="form-group">
            <Field
              name="email"
              placeholder="Enter Email"
              className={
                touched.email
                  ? `form-control ${errors.email ? "invalid" : "valid"}`
                  : `form-control`
              }
              type="text"
            />
              <p className="text-danger height">{errors.email}</p>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="form-group">
            <Field
              as="textarea"
              name="message"
              placeholder="Enter Message"
              rows="7" cols="30"
              className={
                touched.message
                  ? `form-control ${errors.message ? "invalid" : "valid"}`
                  : `form-control`
              }
            ></Field>
              <p className="text-danger height">{errors.message}</p>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="form-submit-button">
                <FormInput tag={"button"} type={"button"} val={"Send Message"}/>
              </div>
            </div>

      </div>
    </Form>
  );
}

export default ContactForm;
