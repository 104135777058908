import React from "react";
// import About Img
import img1 from "../../assets/img/about-page.png";
// import Icon
// import { FiCheck } from "react-icons/fi";

const History = () => {
  return (
    <>
      {/* About Top Area Start */}
      <section id="about-top" className="bg-color pb-5 py100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="about-img left-side-title">
                <h3>About Us</h3>
                <p className="mb-5">
                  Welcome to Calidad Technologies Pvt Ltd a leading IT web
                  development and data science company committed to delivering
                  exceptional solutions and services that drive innovation and
                  empower businesses. Our journey began in January 2023 with a
                  vision to transform the digital landscape and create value
                  through technology.
                </p>
                <h3>Our History</h3>
                <p>
                  Established in 2023, to Calidad Technologies Pvt Ltd has
                  steadily evolved into a powerhouse of technological expertise
                  and innovation. From humble beginnings, we have grown into a
                  reputable company known for crafting cutting-edge solutions
                  that propel businesses forward. Our track record of successful
                  projects and satisfied clients stands as a testament to our
                  dedication and commitment.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="about-top-img animation-img-two">
                <img alt="" src={img1} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about-mission" className="py40 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="about-img left-side-title">
                <h3>Mission</h3>
                <p>
                  At Calidad Technologies Pvt Ltd, our mission is to
                  revolutionize the way businesses operate by leveraging
                  technology to its fullest potential. We strive to provide
                  tailored solutions that exceed expectations, enabling our
                  clients to achieve their goals and outshine in the competitive
                  market.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="about-img left-side-title">
                <h3>Vision</h3>
                <p>
                  Our vision is to be a global leader in IT web development and
                  data science, setting new benchmarks for excellence and
                  driving digital transformation across diverse industries. We
                  aim to foster a culture of innovation, collaboration, and
                  continuous learning, positioning ourselves at the forefront of
                  technological advancements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-culture" className="pt-5 bg-color">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 left-side-title">
              <h3>Company Culture and Values</h3>
              <div className="about-img">
                <p className="mb-4">
                  At the heart of Calidad Technologies Pvt Ltd lies a strong
                  culture built on core values that guide our actions and
                  decisions:
                </p>
                <h5 className="font-weight-bold">Innovation:</h5>
                <p>
                  We foster an environment that encourages creativity and
                  out-of-the-box thinking to deliver innovative solutions.
                </p>
                <h5 className="font-weight-bold">Integrity:</h5>
                <p>
                  We uphold the highest standards of honesty, transparency, and
                  ethical conduct in all our interactions and business
                  practices.
                </p>
                <h5 className="font-weight-bold">Collaboration:</h5>
                <p>
                  We believe in the power of teamwork and collaboration, valuing
                  diverse perspectives and insights that lead to superior
                  outcomes.
                </p>
                <h5 className="font-weight-bold">Client-Centric Approach:</h5>
                <p>
                  Our clients are at the center of everything we do. We
                  prioritize understanding their needs and delivering solutions
                  that exceed their expectations.
                </p>
                <h5 className="font-weight-bold">Excellence:</h5>
                <p>
                  We strive for excellence in all aspects of our work, setting
                  high standards and continuously improving to achieve the best
                  possible results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-culture" className="py100 bg-color">
        <div className="container">
          <div className="row">
            <h2 className="d-flex justify-content-center mb-4 w-100">
              Through our commitment
            </h2>
            <p>
              Through our commitment to these values and a passionate team
              dedicated to our mission and vision, we aim to make a lasting
              impact in the world of technology and drive positive change for
              businesses and communities.
            </p>

            <p>
              Discover more about our services and how we can transform your
              digital presence by exploring the rest of our website. Feel free
              to reach out, and let's embark on a journey of growth and
              innovation together.
            </p>
          </div>
        </div>
      </section>

      {/* About Top Area Start */}
    </>
  );
};

export default History;
