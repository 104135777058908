import React from 'react'
import { Link } from "react-router-dom";
//import CommonBanner from '../component/Common/Banner'
import History from '../component/About/History'
import GetStarted from '../component/Common/GetStarted/index'
// import Counter from '../component/Common/Counter/index'
// import Testimonials from '../component/Common/Testimonials'
//import TeamMembers from '../component/About/Team'

const CommonBannerAbout = (props) => {
  return (
    <>
      <section id="banner-inner-area" className="aboutus-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-details text-center">
                <h2>{props.heading}</h2>
                <ul>
                  <li>
                    <Link to="/">{props.menu1}</Link>
                  </li>
                  <li>
                    <span>/</span>
                    {props.menu2}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const About = () => {
    return (
      <>
        <CommonBannerAbout heading="About" menu1="Home" menu2="About" />
        {/* <CommonBanner heading="About" menu1="Home" menu2="About" /> */}
        <History />
        <GetStarted />
        {/* <Counter/> */}
        {/* <TeamMembers /> */}
        {/* <Testimonials/> */}
      </>
    );
}

export default About
