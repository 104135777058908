import React from "react";

function Formsuccess() {
  return (
    <div className="contact-form-area box-shadow">
      <div className="form-success-area">
        <div className="centered-content">
          <i className="checkmark">✓</i>
        </div>
        <h1>Thank You</h1>
        <div className="centered-content">
          <p>We have received your message.</p>
          <p>We'll be in touch shortly!</p>
        </div>
      </div>
    </div>
  );
}

export default Formsuccess;
