import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logos from "../assets/img/logo.png";
import { HiMenuAlt3 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
// import { MenuData } from "./MenuData";
// import NavItem from "./NavItems";
import { FiChevronDown } from "react-icons/fi";

const Header = () => {
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".navbar-area");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  const [click, setClick] = useState(false);

  const handleClick = () => {
    if (click) {
      document
        .querySelector("#navbarSupportedContent")
        .classList.remove("d-block");
    } else {
      document
        .querySelector("#navbarSupportedContent")
        .classList.add("d-block");
    }
    setClick(!click);
  };

  return (
    <>
      <div className="navbar-area">
        <div className="plamb-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link className="navbar-brand" to="/">
                <img src={logos} className="white-logo" alt="logo" />
                <img src={logos} className="black-logo" alt="logo" />
              </Link>
              <div className="mean-menu" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to ="/">Home</Link>
                    
                  </li>
                  <li className="nav-item">
                  <Link to ="/About">About Us</Link>

                  </li>
                  <li className="nav-item">
                  <Link to ="services">Services
                      <i>
                        <FiChevronDown />
                      </i></Link>
                    <div className="dropdown-menu " aria-labelledby="dropdownMenuButton1">
                      <div className="container">
                         <div className="p-4">
                          <div className="row"> 
                            <div className="col-lg-6">
                              <h5 className="font-weight-bold underline">
                                IT Web Development:
                              </h5>
                              <ul className="list-unstyled grid-list">
                                <li className="nav-item">
                                    <Link to ="">
                                  
                                    Custom web development
                                  </Link>
                                </li>
                                <li className="nav-item">
                                <Link to ="">
                                React Native development

                                  </Link>
                                </li>
                                <li className="nav-item">
                                <Link to ="" >
                                 
                                    Website design
                                  </Link>
                                </li>
                                <li className="nav-item" >
                                <Link to ="">
                                    Web Frameworks
                                  </Link>
                                </li>
                                <li className="nav-item">
                                <Link to ="">
                                 
                                    CMS development
                                  </Link>
                                </li>
                                <li className="nav-item">
                                <Link to ="">
                                E-commerce solutions

                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <h5 className="font-weight-bold underline">
                                Data Science:
                              </h5>
                              <ul className="list-unstyled grid-list">
                                <li className="nav-item">
                                <Link to ="">
                                    DA and DV
                                  </Link>
                                </li>
                                <li className="nav-item">
                                <Link to ="">

                                    ML Solutions
                                  </Link>
                                </li>
                               
                                <li className="nav-item">
                                <Link to ="">
                                  
                                    Predictive modeling
                                  </Link>
                                </li>
                                <li className="nav-item">
                                <Link to ="">

                                    DP and DC
                                  </Link>
                                </li>
                                <li className="nav-item">
                                <Link to ="">
                                  
                                    AI-powered Apps
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                  <Link to ="/Requestquote">Case Studies</Link>

                  </li>

                  <li className="nav-item">
                  <Link to ="/Blog">Blog</Link>

                  </li>
                  <li className="nav-item">
                  <Link to ="/Contact">Contact Us</Link>
                  </li>
                  
                </ul>
                <div className="other-option">
                  <Link className="btn btn-theme" to="/Requestquote">
                    Request a Quote
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="plamb-responsive-nav">
          <div className="container">
            <div className="responsive-button" onClick={handleClick}>
              {click ? <AiOutlineClose /> : <HiMenuAlt3 />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
