import { React, useState } from "react";
import Slider from "react-slider";
import { Form, Field } from "formik";
import FormInput from "../component/From";
import { FaCheck , FaTimes} from "react-icons/fa";

function Requestquoteform({ errors, touched, values }) {
  const [sliderValue, setSliderValue] = useState([20000, 75000]);
  // console.log(values);

  return (
    <Form>
      <div className="row">
        <div className="col-lg-12">
          <label>What services are you interested in ?</label>

          <div className="requestformservices">
            <div
              className={
                values.Machine_learning
                  ? "form-services-control-button-clicked"
                  : "form-services-control-button"
              }
            >
              <label className="form-services-control">
                <Field type="checkbox" name="Machine_learning" /> Machine
                learning
                {values.Machine_learning ? (
                  <FaCheck className="icon-check" />
                ) : (
                  <FaTimes className="icon-check" />
                )}
              </label>
            </div>
            <div
              className={
                values.CMS_development
                  ? "form-services-control-button-clicked"
                  : "form-services-control-button"
              }
            >
              <label className="form-services-control">
                <Field type="checkbox" name="CMS_development" /> CMS development
                {values.CMS_development ? (
                  <FaCheck className="icon-check" />
                ) : (
                  <FaTimes className="icon-check" />
                )}
              </label>
            </div>

            <div
              className={
                values.Web_Developement
                  ? "form-services-control-button-clicked"
                  : "form-services-control-button"
              }
            >
              <label className="form-services-control">
                <Field type="checkbox" name="Web_Developement" /> Web
                Developement
                {values.Web_Developement ? (
                  <FaCheck className="icon-check" />
                ) : (
                  <FaTimes className="icon-check" />
                )}
              </label>
            </div>

            <div
              className={
                values.Website_Design
                  ? "form-services-control-button-clicked"
                  : "form-services-control-button"
              }
            >
              <label className="form-services-control">
                <Field type="checkbox" name="Website_Design" /> Website Design
                {values.Website_Design ? (
                  <FaCheck className="icon-check" />
                ) : (
                  <FaTimes className="icon-check" />
                )}
              </label>
            </div>
          </div>
          <p className="text-danger height">{errors.atLeastOneSelected}</p>
        </div>
        <div className="col-lg-4">
          <div className="requestform-group">
            <label>Can you tell us about your project :</label>
            <div className="form-group pt20">
              <Field
                as="textarea"
                name="message"
                placeholder="Enter Message"
                rows="9"
                className={
                  touched.message
                    ? `form-control ${errors.message ? "invalid" : "valid"}`
                    : `form-control`
                }
              ></Field>
              <p className="text-danger height">{errors.message}</p>
            </div>
          </div>
        </div>
        <div className="requestform col-lg-8">
          <label>What kind of budget are you aiming for ? </label>
          <div className="row">
            <div className="col-lg-12 mb10 formflx">
              <div className="col-lg-8">
                <div className="requestform-group">
                  <Field name="budget">
                    {({ field, form }) => (
                      <Slider
                        value={field.value}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                          setSliderValue(value);
                        }}
                        min={5000}
                        max={100000}
                        range
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="col-lg-4 pricerange ">
                ${sliderValue[0]}- $
                {sliderValue[1] >= 100000
                  ? `${sliderValue[1]} & above`
                  : `${sliderValue[1]}`}
              </div>
            </div>
            <div className="col-lg-6 formflx">
              <div className="requestform-group col-lg-12">
                <div className="form-group ">
                  <label htmlFor="first_name">First Name :</label> <br />
                  <Field
                    name="first_name"
                    placeholder="Enter First Name"
                    className={
                      touched.first_name
                        ? `form-control ${
                            errors.first_name ? "invalid" : "valid"
                          }`
                        : `form-control`
                    }
                    type="text"
                  />
                  <p className="text-danger height10">{errors.first_name}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="last_name">Last Name :</label> <br />
                  <Field
                    name="last_name"
                    placeholder="Enter Last Name"
                    className={
                      touched.last_name
                        ? `form-control ${
                            errors.last_name ? "invalid" : "valid"
                          }`
                        : `form-control`
                    }
                    type="text"
                  />
                  <p className="text-danger height10">{errors.last_name}</p>
                </div>
              </div>

              <div className="requestform-group col-lg-12 ">
                <div className="form-group">
                  <label htmlFor="email">Email :</label> <br />
                  <Field
                    name="email"
                    placeholder="Enter Email"
                    className={
                      touched.email
                        ? `form-control ${errors.email ? "invalid" : "valid"}`
                        : `form-control`
                    }
                    type="text"
                  />
                  <p className="text-danger height10">{errors.email}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="mobile">Mobile Number :</label> <br />
                  <Field
                    name="mobile"
                    placeholder="Enter Mobile Number"
                    className={
                      touched.mobile
                        ? `form-control ${errors.mobile ? "invalid" : "valid"}`
                        : `form-control`
                    }
                    type="number"
                  />
                  <p className="text-danger height10">{errors.mobile}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="requestform-btn col-9">
        <div className="form-submit-button">
          <FormInput tag={"button"} type={"button"} val={"Send"} />
        </div>
      </div>
    </Form>
  );
}

export default Requestquoteform;
