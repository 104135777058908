import logo1 from "../../../assets/img/logo/bharattaxi-logo.png";
import logo2 from "../../../assets/img/logo/boxman-logo.png";
import logo3 from "../../../assets/img/logo/doco-logo.png";
import logo4 from "../../../assets/img/logo/gyaanx-logo.png";
import logo5 from "../../../assets/img/logo/oudhi-logo.png";

export const PartnerData =[
 {
  img:logo1
 },
 {
  img:logo2
 },
 {
  img:logo3
 },
 {
  img:logo4
 },
 {
  img:logo5
 }
 ];

